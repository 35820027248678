import React from 'react';
import { useCallback } from 'react';
import GaugeChart from 'react-gauge-chart'
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { CgFileDocument } from "react-icons/cg";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeFirstLetterOfEachWord, convertUTCToLocal, getPaginationLimit } from "../../utils/common";
import SessionService from '../../services/session.service';
import ResumeViewerPopup from '../../components/JD/ResumeViewerPopup';
import moment from 'moment';

export default function SessionsPage(){
    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => navigate(-1);
    const state = location?.state;
    const interviewType = state?.interviewType;

    const [first, setFirst] = useState(0);
    const [resumeUrl, setResumeUrl] = useState(0);
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(getPaginationLimit());

    const [rows, setRows] = useState(getPaginationLimit());
    const [selectedResume, setSelectedResume] = useState(null);

    const [showResumePopup, setShowResumePopup] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setLimit(getPaginationLimit())
            setRows(getPaginationLimit())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const getSessions = async () => {
        try {
            setLoading(true);
            const response = await SessionService.getSessions(currentPage, limit, interviewType);
            setLoading(false);
            if(response.status === 200){
                const {data, totalCount} = response.data || []; 
                const list = data?.map((item) => ({...item, selected: false}))
                setSessions(list);
                setTotalCount(totalCount);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSessions();
    }, [currentPage, limit]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    const renderEmail = ({meta, _id}) => {
        const email = meta?.['Email'] || meta?.['email'];

        if(email){
            return (
                <div className="flex gap-4 items-center">
                    <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                        {email}
                    </span>
                </div>
            )
        }

        return (
            <div className="flex gap-4 items-center">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    Not Available
                </span>
            </div>
        )
    }
    const renderName = ({meta}) => {
        return (
            <div className="flex gap-4 items-center">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {(meta?.['Name'] || meta?.['name']) ?meta?.['Name'] || meta?.['name'] : 'Not Available'}
                </span>
            </div>
        )
    }


    const showResume = (item) => {
        if(item['resumeUrl'])
            setResumeUrl(item['resumeUrl']);
        setShowResumePopup(true);
    }

    const viewResume = (item) => {
        return (
            <div 
                onClick={(event) => {
                    event?.stopPropagation();
                    setSelectedResume(null)
                }}
                className="flex gap-4 items-center w-10">
                <CgFileDocument 
                    size={20}
                    onClick={showResume.bind(this, item)}
                    className='cursor-pointer text-primary'
                />
            </div>
        )
    }

    const renderScore = useCallback(({score}) => {

        return (
            <div className="flex items-center gap-5">
                <div className="flex flex-col items-center justify-center gap-2">
                    <GaugeChart 
                        id="gauge-chart1" 
                        nrOfLevels={4}
                        hideText={true}
                        style={{ width: 40 }}
                        colors={["#FF3B30", "#FF9500", '#FED000', '#4DC284']} 
                        percent={parseFloat(score).toFixed(1) / 100} 
                    />
                    <span className="text-sm">{parseFloat(score).toFixed(1)}%</span>
                </div>
            </div>
        )
    }, []);

    const renderCreatedAt = ({createdAt}) => {
        return (
            <div className=''>
                <span className='text-sm'>{convertUTCToLocal(createdAt)}</span>
            </div>
         )
    }

    return (
        <div className="flex flex-col h-full gap-4 p-5">
            <div className="flex items-center gap-4 text-darkGray">
                <div className="flex items-center gap-2">
                    <MdKeyboardArrowLeft  
                        size={26}
                        onClick={goBack}
                        className='cursor-pointer'
                    />
                    <h1 className='text-xl text-primary font-bold'>
                        {capitalizeFirstLetterOfEachWord(interviewType?.replace('_', ' '))}  Sessions
                    </h1>
                </div>
            </div>
            {showResumePopup && (
                <ResumeViewerPopup 
                    resumeUrl={resumeUrl}
                    header="Resume"
                    close={() => setShowResumePopup(false)}
                />
            )}
            <div className="bg-white rounded mt-5">
                <DataTable
                    dataKey="_id"
                    editMode="row" 
                    value={sessions}
                    loading={loading}
                    selectionMode='checkbox'
                    className="text-brownGray"
                    emptyMessage="No sessions available" 
                    rowClassName='bg-white hover:text-blue hover:cursor-pointer'
                    onRowClick={({data}) => navigate(`/dashboard/sessions/${data?.['_id']}`)}>
                    <Column 
                        body={viewResume}  
                        className='w-10 p-0 pl-3'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column  
                        header="Title" 
                        field='jdTitle'
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column  
                        header="Name" 
                        body={renderName}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        header="Email" 
                        body={renderEmail}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        header="Score"
                        body={renderScore} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="createdAt" 
                        header="Created Dt"
                        body={renderCreatedAt}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                </DataTable>
            </div>
            <Paginator 
                rows={rows}
                first={first}
                totalRecords={totalCount}
                onPageChange={onPageChange}
                className={`bg-[#ECF5FA] justify-end mt-5 ${totalCount / rows > 1 ? 'visible': 'hidden'}`}
            />
        </div>
    )
};