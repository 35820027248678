import axios from "axios";
import CommonService from "./common.service";
import axiosInstance from ".";

async function recordedFileUpload(props){
    const {fileName, sessionId, file, token} = props;
    const presignUrl = await CommonService.generatePresignUrlForSessionFileUpload(sessionId, fileName, token);
    const {url} = presignUrl.data.data;
    await axios.put(url, file, {
        headers: {
            'x-ms-blob-type': 'BlockBlob',
            // 'x-ms-blob-duration': duration,
            // 'x-ms-blob-starttime': starttime,
            // 'x-ms-blob-endtime': endtime,
        }
    });
}

function getTranscript(sessionId){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'))
    return axios.get(`${process.env.REACT_APP_API_URL}/screening/transcribe/${sessionId}`, {
        headers: { 'Authorization': `Bearer ${accessToken.token}`}
    })
}

function verifySessionKey(key){
    return axiosInstance.post('/verifySessionKey', {key })
}

function getSessions(page, limit, interviewType){
    return axiosInstance.get(`/sessions?page=${page}&limit=${limit}&interviewType=${interviewType}`)
}

function getSession(sessionId){
    return axiosInstance.get(`/sessions/${sessionId}`)
}


const SessionService = {
    getSessions,
    getTranscript,
    verifySessionKey,
    recordedFileUpload,
    getSession
}

export default SessionService;