import { Route, Routes } from "react-router-dom";
import SessionsPage from "../pages/Session/SessionsPage";
import SessionPage from "../pages/Session/SessionPage";

export default function SessionsRoutes(){
    return (
        <Routes>
            <Route index path="" element={<SessionsPage />} />
            <Route path=":id" element={<SessionPage />} />
        </Routes>
    )
}